import React from "react";
import { useEffect } from "react";
import { Parser } from "html-to-react";
import "./Events.scss";

function EventsDocumentsModal({ title, img, setLink, data }) {
  console.log("img -", img);

  const images = Array.isArray(data?.attachments)
    ? data.attachments
    : typeof data?.attachments === "string"
    ? JSON.parse(data.attachments)
    : [];

  // console.log("images -", images);

  const htmlParser = new Parser();

  const isPdf = (url) => url.endsWith(".pdf");

  return (
    <div
      className="modal fade text-left"
      id="EventsDocumentsModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Images and Documents
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="events-details-left-content pr-20">
                    {images && images.length > 1 ? (
                      <div
                        id="carouselExampleInterval"
                        className="carousel slide"
                        data-bs-ride="carousel"
                      >
                        <div className="carousel-indicators">
                          {images.map((item, index) => (
                            <button
                              key={index}
                              type="button"
                              data-bs-target="#carouselExampleInterval"
                              data-bs-slide-to={index}
                              className={index === 0 ? "active" : ""}
                              aria-label={`Slide ${index + 1}`}
                            />
                          ))}
                        </div>
                        <div className="carousel-inner">
                          {images.map((image, index) => (
                            <div
                              key={index}
                              className={`carousel-item ${
                                index === 0 ? "active" : ""
                              }`}
                              data-bs-interval="4000"
                            >
                              {isPdf(image) ? (
                                <iframe
                                  src={`${image}#toolbar=0`}
                                  className="d-block w-100"
                                  style={{ height: "700px" }}
                                  title={`Slide ${index + 1}`}
                                ></iframe>
                              ) : (
                                <img
                                  src={image}
                                  className="d-block w-100 viewimage"
                                  alt="..."
                                />
                              )}
                            </div>
                          ))}
                        </div>
                        <button
                          className="carousel-control-prev"
                          type="button"
                          data-bs-target="#carouselExampleInterval"
                          data-bs-slide="prev"
                        >
                          <span
                            className="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Previous</span>
                        </button>
                        <button
                          className="carousel-control-next"
                          type="button"
                          data-bs-target="#carouselExampleInterval"
                          data-bs-slide="next"
                        >
                          <span
                            className="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Next</span>
                        </button>
                      </div>
                    ) : images && images.length === 1 ? (
                      isPdf(images[0]) ? (
                        <div className="events-image text-center">
                          <iframe
                            src={`${images[0]}#toolbar=0`}
                            className="d-block w-100"
                            style={{ height: "700px" }}
                            title="PDF Document"
                          ></iframe>
                        </div>
                      ) : (
                        <div className="events-image text-center">
                          <img
                            style={{ objectFit: "fit", height: "700px" }}
                            src={images[0]}
                            alt="Image"
                            className="viewimage"
                          />
                        </div>
                      )
                    ) : (
                      <p>No image available</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventsDocumentsModal;
